
html,body {
    margin: 0%;
    width:100%;
    background-color: #F0F2F7;
}

.mat-tooltip {
    font-size: 14px;
    max-width: 350px;
    white-space: normal;
    padding: 12px;
    background-color: #901313;
    color: white;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    line-height: 1.4;
  }
  